<style lang="scss">
.kinderzeitung {
  [class*='col-'] {
  }
  .themen-box {
    padding: 15px;
    .bild-box {
      padding: 15px 0;
    }
    h4 {
      font-size: 120%;
      text-align: center;
      margin-bottom: 29px;
    }
  }

  .fakten-all {
    margin-bottom: 20px;
    @media (max-width: 992px) {
      margin: 40px 0;
    }
    .headline {
      font-weight: 700;
      text-transform: uppercase;
      font-size: 120%;
    }
    .fakten {
      margin-top: 10px;
      margin-bottom: 10px;
      strong {
        text-transform: uppercase;
        display: inline-block;
        margin-right: 5px;

        @media (max-width: 992px) {
          display: block;
        }
      }
      margin-bottom: 10px;
    }
  }
  .tabelle-box {
    .headline {
      margin-bottom: 20px;
    }
    .head-row {
      @media (max-width: 1550px) {
        font-size: 80%;
      }
      @media (max-width: 1300px) {
        font-size: 70%;
      }
      @media (max-width: 992px) {
        font-size: 90%;
      }
    }
  }

  .themen-box {
    .col-lg-6 {
      margin-bottom: 20px;
    }
    padding: 15px;
    .bild-box {
      padding: 15px 0;
      height: 300px;
      img {
        max-height: 100%;
        display: block;
        margin: 0 auto;
      }
    }
    .headline {
      font-size: 120%;
      text-align: center;
      margin-bottom: 20px;
    }
    .content-link-modal {
      display: block;
      text-decoration: none;
      text-align: center;
      padding: 10px 15px;
      border-top: 3px solid $blue-darker;
      max-width: 100%;
      margin: 0 auto;
      &:hover {
        opacity: 0.7;
        color: $blue;
      }
    }
  }
  .btn-blaettern {
    margin: 50px 0;
    //border-top: 1px solid #eee;
    a {
      display: block;
      &:before {
        content: '';
        display: block;
        width: 50px;
        height: 3px;
        background: #eee;
        margin: 10px auto;
      }
      img {
        max-width: 50%;
        margin: 0 auto;
        display: block;
      }
      span {
        text-align: center;
        display: block;
        margin: 10px 0;
      }
      &:hover {
        color: $font-color;
        opacity: 0.8;
      }
    }
  }
  .tabelle-box {
    margin-top: 10px;
    .text-align-right-small {
      @media (max-width: 992px) {
        text-align: right !important;
      }
    }
  }
}
.tab-group-container {
  .title.active {
  }
  .title-container {
    @media (max-width: 768px) {
      display: block !important;
    }
  }
  .title {
    padding: 15px 20px;
    @media (max-width: 768px) {
      display: block;
      width: 100%;
      text-align: center;
    }
  }
  .inner-content {
    [class*='col-'] {
      justify-items: center;
      align-self: flex-start;
    }
    padding: 15px;
    img {
      display: block;
      margin: 0 auto;
    }
    .fakten {
      margin-bottom: 10px;
    }
    .leistung {
      &:before {
        content: '';
        display: block;
        margin: 20px 0;
        width: 150px;
        height: 5px;
        background: $futter-red;
      }
      &:after {
        content: '';
        display: block;
        margin: 20px 0;
        width: 100px;
        height: 5px;
        background: $futter-red;
      }
      strong {
        display: block;
        font-size: 140%;
        text-transform: uppercase;
      }
      span {
        display: block;
        margin-top: 5px;
      }
      .preis {
        display: block;
        background: $futter-red;
        color: #fff;
        padding: 10px 15px;
        display: inline-block;
        margin-top: 10px;
      }
    }
    .bsp {
      margin-top: 30px;

      .bsp-headline {
        position: relative;
        text-align: center;
        margin-bottom: 20px;
        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          background: #000;
          position: absolute;
          top: 50%;
        }
        span {
          display: inline-block;
          padding: 10px 15px;
          text-transform: uppercase;
          letter-spacing: 1px;
          font-size: 90%;
          background: #fff;
          position: relative;
          z-index: 2;
        }
      }
      a {
        display: block;

        text-align: center;
        span {
          display: block;
          padding: 10px 15px;
          background: $futter-red;
          color: #fff;
        }

        img {
          padding: 15px;
        }
        &:hover {
          opacity: 0.7;
          transform: scale(0.95);
        }
      }
    }
  }
}
</style>

<template>
  <div class="content kinderzeitung container">
    <h1>Kleine Kinderzeitung</h1>
    <p class="intro-txt">
      <strong>Wissen fängt mit Lesen an.</strong> Als Österreichs einzige Kinderwochenzeitung informiert die Kleine Kinderzeitung jeden Samstag Kinder im Alter von sechs bis zwölf Jahren über das aktuelle Weltgeschehen, verrät Neuigkeiten aus dem Tierreich, gibt spannende Einblicke in die Sportwelt und sorgt mit Rätsel und Lese-Ratekrimis für jede Menge Unterhaltung. Print und online auf
      <a href="https://kinderzeitung.kleinezeitung.at/" target="_blank" class="content-link">kleinezeitung.at/kinderzeitung </a>.
    </p>

    <div class="row margin-40-0">
      <div class="col-lg-6 justify-content-center align-self-center">
        <img src="@/assets/img/img-content/junge-zielgruppe/kinderzeitung.jpg" alt="Kinderzeitung Titelblatt" class="img-fluid img-s-75 img-center shadow" />
        <div class="btn-std btn-bg-blue-dark margin-t-s text-center">
          <a href="../../pdf/junge-zielgruppe/Kinderzeitung-Jubilaeumsausgabe-2301.pdf" target="_blank">Werfen Sie einen Blick in die Jubiläums-Ausgabe zum 10. Geburtstag</a>
        </div>
      </div>
      <div class="col-lg-6 justify-content-center align-self-center">
        <div class="fakten-all">
          <div class="row fakten">
            <div class="col-lg-5"><strong>Format</strong></div>
            <div class="col-lg-7">206 x 276 mm</div>
          </div>
          <div class="row fakten">
            <div class="col-lg-5"><strong>Erscheinungstermin</strong></div>
            <div class="col-lg-7">jeden Samstag</div>
          </div>
          <div class="row fakten">
            <div class="col-lg-5"><strong>Vertrieb</strong></div>
            <div class="col-lg-7">nur im Abo erhältlich</div>
          </div>
          <div class="row fakten">
            <div class="col-lg-5"><strong>Zielgruppe</strong></div>
            <div class="col-lg-7">Kinder im Alter von sechs bis zwölf</div>
          </div>
        </div>
        <div class="tabelle-box">
          <div class="row head-row">
            <div class="col-6 text-align-left">Erscheinungsgebiet</div>
            <div class="col-6 text-align-right">Auflage</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left">Kärnten</div>
            <div class="col-6 text-align-right">3.500</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left">Steiermark</div>
            <div class="col-6 text-align-right">10.500</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left">Kombination</div>
            <div class="col-6 text-align-right">14.000</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left">National</div>
            <div class="col-6 text-align-right">20.000</div>
          </div>
        </div>
      </div>
    </div>
    <div class="margin-tb-m"></div>
    <h2>Ihre Werbemöglichkeiten Print</h2>
    <p>Werden Sie Teil der Kleinen Kinderzeitung und nutzen Sie das umfangreiche Produktportfolio.</p>

    <TabGroup>
      <Tab tabid="kiz-tab1" :title="'Sonderausgabe'" active>
        <div class="row inner-content">
          <div class="col-lg-5 justify-content-center align-self-center">
            <div class="bild-box">
              <img src="@/assets/img/img-content/junge-zielgruppe/Sonderausgabe-kinderzeitung.jpg" alt="Kinderzeitung Sonderausgabe" class="img-fluid img-s-75 shadow img-center" />
              <div class="btn-std btn-bg-blue-dark margin-t-s text-center">
                <a href="../../pdf/sonderprodukte/Sonderausgabe-kinderzeitung-low.pdf" target="_blank">Erfolgsbeispiel zum Durchblättern </a>
              </div>
            </div>
          </div>
          <div class="col-lg-7 justify-content-center align-self-center">
            <h2>Sonderausgabe</h2>
            <ul class="content-ul">
              <li>Exklusives Produkt auf Ihre Bedürfnisse zugeschnitten</li>
              <li>Enge thematische Abstimmung zwischen Ihnen und unserer Redaktion</li>
              <li>Referenz-Kunden: Wirtschaftskammer Österreich, Ministerium für Landesverteidigung, Ärztekammer Steiermark, Rotes Kreuz Kärnten</li>
            </ul>
            <br />
            <h2>Leistungen &amp; Tarif</h2>
            <div class="row fakten">
              <div class="col-lg-3"><strong>Mindesauflage</strong></div>
              <div class="col-lg-6">10.000 Stück</div>
            </div>
            <div class="row fakten">
              <div class="col-lg-3"><strong>Umfang</strong></div>
              <div class="col-lg-6">16, 24 oder 32 Seiten</div>
            </div>
            <div class="row fakten">
              <div class="col-lg-3 align-self-start"><strong>Verteilung</strong></div>
              <div class="col-lg-6">nach Wusch: Abonnenten der Kleinen Kinderzeitung, Überdruck zur eigenen Verwendung, Verteilung an Schulen und mehr.</div>
            </div>
            <div class="row fakten">
              <div class="col-lg-3 align-self-start"><strong>Vorlaufzeit</strong></div>
              <div class="col-lg-6">ca. 8 Wochen</div>
            </div>
            <div class="row fakten">
              <div class="col-lg-12">
                <div class="btn-std btn-bg-blue-dark margin-t-s text-center">
                  <router-link :to="{ name: 'KontaktFormular' }">Tarif auf Anfrage </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center">
          <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 5% Werbeabgabe und 20% Umsatzsteuer</div>
        </div>
      </Tab>
      <Tab tabid="kiz-tab2" :title="'PR-Strecke'">
        <div class="row inner-content">
          <div class="col-lg-5 justify-content-center align-self-center">
            <div class="bild-box">
              <img src="@/assets/img/img-content/junge-zielgruppe/kinderzeitung-pr-strecke.jpg" alt="Kinderzeitung PR-Strecke" class="img-fluid img-s-75 shadow img-center" />
            </div>
          </div>
          <div class="col-lg-7 justify-content-center align-self-center">
            <h2>PR-Strecke</h2>
            <ul class="content-ul">
              <li>Ein- oder mehrseitige PR-Strecke</li>
              <li>Bietet die Möglichkeit für interaktive Elemente (Gewinnspiel, Wettbewerb,...)</li>
              <li>Fokus: Bildsprache mit Erklärungen (keine reine Produktwerbung)</li>
              <li>Professionelle Gestaltung durch unsere PR-Abteilung</li>
              <li>Übermittlung und Integration Ihrer Bilder</li>
            </ul>
            <br />
            <div class="tabelle-box">
              <h2>Leistungen &amp; Tarif</h2>
              <div class="row head-row hide-small">
                <div class="col-lg-4 text-align-left">Umfang</div>
                <div class="col-lg-2 text-align-right">Kombination</div>
                <div class="col-lg-2 text-align-right">Steiermark</div>
                <div class="col-lg-2 text-align-right">Kärnten</div>
                <div class="col-lg-2 text-align-right">National</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Umfang</div>
                <div class="col-lg-4 col-6 text-align-left text-align-right-small">1-seitig</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-2 col-6 text-align-right">3.500,00</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-2 col-6 text-align-right">2.900,00</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-2 col-6 text-align-right">1.450,00</div>
                <div class="col-6 text-align-left visible-small">national</div>
                <div class="col-lg-2 col-6 text-align-right">4.850,00</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Umfang</div>
                <div class="col-lg-4 col-6 text-align-left text-align-right-small">2-seitig</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-2 col-6 text-align-right">5.350,00</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-2 col-6 text-align-right">4.450,00</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-2 col-6 text-align-right">2.250,00</div>
                <div class="col-6 text-align-left visible-small">national</div>
                <div class="col-lg-2 col-6 text-align-right">7.450,00</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Umfang</div>
                <div class="col-lg-4 col-6 text-align-left text-align-right-small">4-seitig</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-2 col-6 text-align-right">7.100,00</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-2 col-6 text-align-right">5.800,00</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-2 col-6 text-align-right">3.000,00</div>
                <div class="col-6 text-align-left visible-small">national</div>
                <div class="col-lg-2 col-6 text-align-right">9.800,00</div>
              </div>

              <div class="info-txt-tabelle">Vorlaufzeit: ca. 5 Wochen / Überdruck auf Anfrage</div>
            </div>
          </div>
        </div>
        <div class="info-txt-preis text-center">Alle Preise verstehen sich in Euro, exklusive 5% Werbeabgabe und 20% Umsatzsteuer. Exklusive <router-link :to="{ name: 'NativeContent' }" class="content-link">PR-Produktionskosten</router-link></div>
      </Tab>
      <Tab tabid="kiz-tab3" :title="'Saisonale Beilage'">
        <div class="row inner-content">
          <div class="col-lg-5 justify-content-center align-self-center">
            <div class="bild-box">
              <img src="@/assets/img/img-content/junge-zielgruppe/kinderzeitung-saisonale-beilage.jpg" alt="Kinderzeitung Saisonale Beilage" class="img-fluid img-s-75 shadow img-center" />
            </div>
          </div>
          <div class="col-lg-7 justify-content-center">
            <h2>Saisonale Beilage</h2>
            <ul class="content-ul">
              <li>Starker Werbeeffekt durch lange Nutzungsdauer</li>
              <li><strong>Frühlings-/ Sommerbeilage</strong> Erscheinungstermin: <strong>26. März 2022</strong></li>
              <li><strong>Herbst-/ Winterbeilage</strong> Erscheinungstermin: <strong>10. September 2022</strong> <span class="breaker"></span><small>(Saisonale Beilage erscheint nur bei Buchung von mindestens 2 Seiten.)</small></li>
            </ul>
            <br />
            <h2>Leistungen &amp; Tarif</h2>
            <br />

            <div class="row">
              <div class="col-lg-12">
                <div class="row fakten ">
                  <div class="col-lg-3 align-self-start"><strong>Auflage</strong></div>
                  <div class="col-lg-6">14.000 Stück inkl. 100 Stück Überdruck für Kunden zur eigenen Verteilung</div>
                </div>
                <div class="row fakten">
                  <div class="col-lg-3 align-self-start"><strong>Format</strong></div>
                  <div class="col-lg-6">ca. A2</div>
                </div>
                <div class="row fakten">
                  <div class="col-lg-3 align-self-start"><strong>Umfang</strong></div>
                  <div class="col-lg-6">4 Seiten für Kunden</div>
                </div>
                <div class="row fakten">
                  <div class="col-lg-3 align-self-start"><strong>Gestaltung</strong></div>
                  <div class="col-lg-6">Gestaltung im Stil der Kinderzeitung, Logopräsenz des Kunden. 4 Seiten werden redaktionell durch Rätsel, Rezepte und mehr ergänzt.</div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12">
                <div class="tabelle-box">
                  <div class="row head-row hide-small">
                    <div class="col-lg-6 text-align-left">Umfang</div>
                    <div class="col-lg-6 text-align-right">Tarif</div>
                  </div>
                  <div class="row content-row">
                    <div class="col-6 text-align-left visible-small">Umfang</div>
                    <div class="col-lg-6 col-6 text-align-left text-align-right-small">1-seitig</div>
                    <div class="col-6 text-align-left visible-small">Tarif</div>
                    <div class="col-lg-6 col-6 text-align-right">1.800,00</div>
                  </div>
                  <div class="row content-row">
                    <div class="col-6 text-align-left visible-small">Umfang</div>
                    <div class="col-lg-6 col-6 text-align-left text-align-right-small">4-seitig</div>
                    <div class="col-6 text-align-left visible-small">Tarif</div>
                    <div class="col-lg-6 col-6 text-align-right">6.750,00</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="info-txt-preis text-center">Alle Preise verstehen sich in Euro, exklusive 5% Werbeabgabe und 20% Umsatzsteuer. Exklusive <router-link :to="{ name: 'NativeContent' }" class="content-link">PR-Produktionskosten</router-link></div>
      </Tab>

      <Tab tabid="kiz-tab4" :title="'Freizeittipps'">
        <div class="row inner-content">
          <div class="col-lg-5 justify-content-center align-self-center">
            <div class="bild-box">
              <img src="@/assets/img/img-content/junge-zielgruppe/kiz-freizeittipps.jpg" alt="Kinderzeitung Freizeittipps" class="img-fluid img-s-75 shadow img-center" />
            </div>
          </div>
          <div class="col-lg-7 justify-content-center align-self-center">
            <h2>Freizeittipps</h2>
            <ul class="content-ul">
              <li>Ob Film-, Ausflugs- oder Buchtipp: Geben Sie Ihre individuelle Empfehlung zur Freizeitgestaltung.</li>
            </ul>
            <div class="row">
              <div class="col-lg-12">
                <div class="row fakten">
                  <div class="col-lg-3"><strong>Format</strong></div>
                  <div class="col-lg-6">110 x 110 mm</div>
                </div>
                <div class="row fakten">
                  <div class="col-lg-3"><strong>Gestaltung</strong></div>
                  <div class="col-lg-6">Logo und Bildpräsenz</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <br />
                <h2>Leistungen &amp; Tarif</h2>
                <div class="tabelle-box">
                  <div class="row head-row hide-small">
                    <div class="col-lg-6 text-align-left">Erscheinungsgebiet</div>
                    <div class="col-lg-6 text-align-right">Tarif</div>
                  </div>
                  <div class="row content-row">
                    <div class="col-6 text-align-left visible-small">Erscheinungsgebiet</div>
                    <div class="col-lg-6 col-6 text-align-left text-align-right-small">Kärnten</div>
                    <div class="col-6 text-align-left visible-small">Tarif</div>
                    <div class="col-lg-6 col-6 text-align-right">500,00</div>
                  </div>
                  <div class="row content-row">
                    <div class="col-6 text-align-left visible-small">Erscheinungsgebiet</div>
                    <div class="col-lg-6 col-6 text-align-left text-align-right-small">Steiermark</div>
                    <div class="col-6 text-align-left visible-small">Tarif</div>
                    <div class="col-lg-6 col-6 text-align-right">500,00</div>
                  </div>
                  <div class="row content-row">
                    <div class="col-6 text-align-left visible-small">Erscheinungsgebiet</div>
                    <div class="col-lg-6 col-6 text-align-left text-align-right-small">Kombination*</div>
                    <div class="col-6 text-align-left visible-small">Tarif</div>
                    <div class="col-lg-6 col-6 text-align-right">750,00</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="info-txt-preis text-center">Alle Preise verstehen sich in Euro, exklusive 5% Werbeabgabe und 20% Umsatzsteuer. Exklusive <router-link :to="{ name: 'NativeContent' }" class="content-link">PR-Produktionskosten</router-link></div>
      </Tab>
    </TabGroup>
    <h2>Ihre digitalen Werbemöglichkeiten</h2>
    <p>Die Kleine Kinderzeitung gibt’s auch online auf <a href="https://kleinezeitung.at/kinderzeitung" target="_blank">kleinezeitung.at/kinderzeitung</a> – mit spannenden News für Kids und attraktiven Werbemöglichkeiten für Sie.</p>
    <TabGroup>
      <Tab tabid="kiz-digital-tab1" :title="'Native Ad'" active>
        <div class="row inner-content">
          <div class="col-lg-5 justify-content-center align-self-center">
            <div class="bild-box">
              <img src="@/assets/img/img-content/sonderprodukte/kiz-native-ad.png" alt="Kinderzeitung Native Ad" class="img-fluid" />
            </div>
          </div>
          <div class="col-lg-7 justify-content-center align-self-center">
            <h2>Native Ad</h2>
            <ul class="content-ul">
              <li>Ein digitaler PR-Text professionell aufbereitet von unserem Content-Service-Team.</li>
              <li>Platzierung auf der Startseite inkl. Logo und Verlinkung auf Ihre Homepage.</li>
            </ul>
            <h3>Leistungen &amp; Tarif</h3>

            <div class="row">
              <div class="col-lg-12">
                <div class="row fakten">
                  <div class="col-lg-5"><strong>Anzeigenschluss</strong></div>
                  <div class="col-lg-6">2 Wochen vor Erscheinungstermin</div>
                </div>
                <div class="row fakten">
                  <div class="col-lg-5"><strong>Druckunterlagenschluss</strong></div>
                  <div class="col-lg-6">1 Woche vor Erscheinungstermin</div>
                </div>
                <div class="row fakten">
                  <div class="col-lg-5"><strong>Artikellänge (Richtwert)</strong></div>
                  <div class="col-lg-6">1.500 Zeichen inkl. Leerzeichen</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="tabelle-box">
                  <div class="row head-row hide-small">
                    <div class="col-lg-6 text-align-left">Native Ad</div>
                    <div class="col-lg-6 text-align-right">Tarif</div>
                  </div>
                  <div class="row content-row">
                    <div class="col-6 text-align-left visible-small">Native Ad</div>
                    <div class="col-lg-6 col-6 text-align-left text-align-right-small">pro Artikel</div>
                    <div class="col-6 text-align-left visible-small">Tarif</div>
                    <div class="col-lg-6 col-6 text-align-right">1.250,00</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="info-txt-preis">Alle Preise verstehen sich in Euro exklusive 20% Umsatzsteuer.</div>
      </Tab>
      <Tab tabid="kiz-digital-tab2" :title="'Do it yourself - PR'">
        <div class="row inner-content">
          <div class="col-lg-5 justify-content-center align-self-center">
            <div class="bild-box">
              <img src="@/assets/img/img-content/sonderprodukte/kiz-do-it-yourself-pr.png" alt="Kinderzeitung Do it yourself PR" class="img-fluid" />
            </div>
          </div>
          <div class="col-lg-7 justify-content-center align-self-center">
            <h2>Do it yourself - PR</h2>
            <ul class="content-ul">
              <li>Interessante Bastelanleitungen und einfache Rezepte in Form eines Artikels</li>
              <li>Verlinkung in den Bereich „Zeitvertreib“</li>
            </ul>
            <h3>Leistungen &amp; Tarif</h3>

            <div class="row">
              <div class="col-lg-12">
                <div class="row fakten">
                  <div class="col-lg-5"><strong>Anzeigenschluss</strong></div>
                  <div class="col-lg-6">2 Wochen vor Erscheinungstermin</div>
                </div>
                <div class="row fakten">
                  <div class="col-lg-5"><strong>Druckunterlagenschluss</strong></div>
                  <div class="col-lg-6">1 Woche vor Erscheinungstermin</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="tabelle-box">
                  <div class="row head-row hide-small">
                    <div class="col-lg-6 text-align-left">Do it yourself PR</div>
                    <div class="col-lg-6 text-align-right">Tarif</div>
                  </div>
                  <div class="row content-row">
                    <div class="col-6 text-align-left visible-small">Do it yourself PR</div>
                    <div class="col-lg-6 col-6 text-align-left text-align-right-small">pro Element</div>
                    <div class="col-6 text-align-left visible-small">Tarif</div>
                    <div class="col-lg-6 col-6 text-align-right">550,00</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="info-txt-preis">Alle Preise verstehen sich in Euro exklusive 20% Umsatzsteuer.</div>
      </Tab>
    </TabGroup>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import TabGroup from '@/components/util/TabGroup.vue';
import Tab from '@/components/util/Tab.vue';

export default defineComponent({
  name: 'Kinderzeitung',
  components: {
    TabGroup,
    Tab,
  },
});
</script>
